import React, { useState, useEffect, useMemo, useRef } from "react";
import * as XLSX from "xlsx";
import { Row, Col, Form as BootstrapForm, Button } from "react-bootstrap";
import Select from "react-select";
import {
  // Col,
  // Row,
  Card,
  Form,
  // Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { FieldArray } from "formik";
import { MDBDataTable } from "mdbreact";

import "react-time-picker/dist/TimePicker.css";
import JoditEditor from "jodit-react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
// import DownloadExcelButton from "./downloadExel";
// import { MDBDataTable } from "mdbreact";
import { useLocation } from "react-router-dom";

import { useHistory } from "react-router-dom";
import { MaterialReactTable } from "material-react-table";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ReactLogo from "../assets/img/technologies/loading-loader-svgrepo-com.svg";
import { Image } from "@themesberg/react-bootstrap";
export const Book = () => {
  const [selectedMetalType, setSelectedMetalType] = useState("Pending");

  const options = [
    { value: "64f84c37852e6b7e2da8a4aa", label: "Subscription 1" },
    { value: "653286a82468c632b1523e8e", label: "Subscription 2" },
    // Add more options as needed
  ];
  const [SubscriptionData, setMySubscriptionData] = useState([]);
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [imgReload, setImgReload] = useState(false);
  // const [updatedBookData, setUpdatedBookData] = useState(null);
  // const handleSubmit = (values) => {
  //   updateBook(editData._id, values);
  // };
  // const [datatable,setDatatale] = useState ({});
  // edit model
  const [Editopen, setEditOpen] = useState(false);
  const onEditOpenModal = () => setEditOpen(true);
  const onEditCloseModal = () => setEditOpen(false);

  // const [openOffer, setOpenOffer] = useState(false);
  // const onOpenOfferModal = () => setOpenOffer(true);
  // const onCloseOffereModal = () => setOpenOffer(false);

  const [EditOfferopen, setEditOfferOpen] = useState(false);
  const onEditOpenOfferModal = () => setEditOfferOpen(true);
  const onEditCloseOfferModal = () => setEditOfferOpen(false);

  const [jsonData, setJsonData] = useState([]);
  const [preparingFor, setPreparingFor] = useState([]);

  const [value, setValue] = useState("1");
  


  const buttonStyles = (type) => ({
    backgroundColor: selectedMetalType === type ? 'rgb(0, 123, 255)' : 'white',
    color: selectedMetalType === type ? 'white' : 'black',
  });


  // const [myData, setMyData] = useState({
  //   code: "",
  //   discountPrize: "",
  //   preparing_for: "",

  // });

  const fileName = "book";
  // const downloadCSV = () => {
  //   // Add "No" field to each item in jsonData
  //   const jsonDataWithNo = jsonData.map((item, index) => ({
  //     No: index + 1, // Adding 1 to make it 1-based index
  //     ...item,
  //   }));

  //   // Convert JSON data to worksheet with specified columns only
  //   const ws = XLSX.utils.json_to_sheet(jsonDataWithNo, {
  //     header: columnOrder,
  //   });

  //   console.log("🚀 ~ downloadCSV ~ jsonDataWithNo:", jsonDataWithNo);

  //   // Convert the worksheet to CSV
  //   const csv = XLSX.utils.sheet_to_csv(ws);

  //   // Create a blob from the CSV and download it
  //   const blob = new Blob([csv], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = `${fileName}.csv`;
  //   a.click();
  //   URL.revokeObjectURL(url);
  // };
  const downloadCSV = () => {
    // Add "No" field to each item in jsonData
    const jsonDataWithNo = jsonData.map((item, index) => ({
      No: index + 1, // Adding 1 to make it 1-based index
      ...item,
    }));
var newjsondata = []
jsonDataWithNo.forEach(element => {
      element['address'] = element.buildingNumber;
      newjsondata.push(element)
    });
  
    // Convert JSON data to worksheet with specified columns only
    const ws = XLSX.utils.json_to_sheet(newjsondata, {
      header: columnOrder,
    });
  
    console.log("🚀 ~ downloadCSV ~ jsonDataWithNo:", newjsondata);
  
    // Convert the worksheet to CSV
    const csv = XLSX.utils.sheet_to_csv(ws, {
      header: columnOrder,
    });
  
    // Create a blob from the CSV and download it
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  const columnOrder = [
    "No",
    "name",
    "price",
    "email",
    "phone",
    "City",
    // "address",
    "Pincode",
    "State",
  ];

  const [myData, setMyData] = useState({
    bookName: "",
    description: "",
    bookType: "",
    bookImg: "",
    volume: "",
    price: "",
    comboPrice: "",
    preparing_for: [],
    subscription_id: [],
    notesOverview: [],
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [isError, setIsError] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [editData, setEditData] = useState({});

  const [editBookOderData, setEdiBookOderData] = useState({});
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);
  const editor = useRef(null);

  const handleCheckboxChange = (event) => {
    setShowExplanation(event.target.checked);
  };
  useEffect(() => {
    // getMySubscriptionData();
    getMySubscriptionData();
    getPreparingFor();
    setUpdatedBookData();
    getMyBookorderData();
    getMyBookData();
  }, []);

  const columns1 = useMemo(
    () => [
      {
        accessorKey: "bookName",
        header: "BookName",
      },
      {
        accessorKey: "volume",
        header: "Volume",
      },
      {
        accessorKey: "price",
        header: "Price",
      },
      {
        accessorKey: "comboPrice",
        header: "ComboPrice",
      },
      {
        accessorKey: "bookType",
        header: "BookType",
      },
      // {
      //   accessorKey: "bookImg",
      //   header: "BookImg",
      // },
      // {
      //   accessorKey: "bookImg",
      //   header: "bookImg",
      //   Cell: ({ renderedCellValue }) => {
      //     return (
      //       <img
      //         src={`data:image/png;base64,${renderedCellValue}`} // Prepend the prefix
      //         alt="BOOK"
      //         style={{ width: "110px", height: "4rem" }} // Adjust styling as needed
      //       />
      //     );
      //   },
      // },

      {
        accessorKey: "description",
        header: "Description ",
      },
      {
        accessorKey: "Neet_SS",
        header: "Neet_SS",
      },
      {
        accessorKey: "INISS_ET",
        header: "INISS_ET",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );

  const columns5 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "price",
        header: "Price",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone",
        header: "Phone",
      },
      {
        accessorKey: "bookName",
        header: "BookName",
      },
      {
        accessorKey: "buildingNumber",
        header: "Address ",
      },
      {
        accessorKey: "City",
        header: "City",
      },
      {
        accessorKey: "LandMark",
        header: "LandMark",
      },
      {
        accessorKey: "Pincode",
        header: "Pincode",
      },
      {
        accessorKey: "State",
        header: "State",
      },
      {
        accessorKey: "status",
        header: "Status",
      },

      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
    []
    //end
  );

  const getMyBookorderData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrderByStatus?status=Pending`,
        { headers: { Authorization: token } }
      );
      console.log(res.data, "pending$$$$$$$$$$$$$$$$$$$$$$$$$");
      const data = res.data?.map((item, index) => ({
        id: index + 1,
        status: item.status,
        Price: item.Price,
        phone: item.phone,
        name: item.name,
        email: item.email,
        address: item.address,
        order_id: item.order_id,

        actions: (
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit1(item)} />
            <FontAwesomeIcon
              className="mx-3"
              icon={faTrash}
              onClick={() => handleBookOrderDelete(item._id)}
            />
          </div>
        ),
      }));

      setJsonData(data);
    } catch (error) {
      console.error(error.response || error.message);
      setIsError(error.response || error.message);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getMycompleteData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrderByStatus?status=complete`,
        { headers: { Authorization: token } }
      );
      console.log(res.data, "competed&&&&&&&&&&&&&&&&&&&&&&&&&&");
      const data = res.data?.map((item, index) => ({
        id: index + 1,
        status: item.status,
        price: item.price,
        phone: item.phone,
        name: item.name,
        email: item.email,
        buildingNumber: item.buildingNumber,
        City: item.City,
        LandMark: item.LandMark,
        Pincode: item.Pincode,
        State: item.State,
        order_id: item.order_id,
        actions: (
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit1(item)} />
            <FontAwesomeIcon
              className="mx-3"
              icon={faTrash}
              onClick={() => handleBookOrderDelete(item._id)}
            />
          </div>
        ),
      }));

      setJsonData(data);
      
    } catch (error) {
      console.error(error.response || error.message);
      setIsError(error.response || error.message);
    } finally {
      setIsLoadingData(false);
    }
  };

  const getMyprocessingData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrderByStatus?status=Processing`,
        { headers: { Authorization: token } }
      );
      console.log(res.data, "processing&&&&&&&&&&&&&&&&&&&&&&&&&&");
      const data = res.data?.map((item, index) => ({
        id: index + 1,
        status: item.status,
        price: item.price,
        phone: item.phone,
        name: item.name,
        email: item.email,
        buildingNumber: item.buildingNumber,
        // address: item.address,
        City: item.City,
        LandMark: item.LandMark,
        Pincode: item.Pincode,
        State: item.State,
        order_id: item.order_id,
        actions: (
          <div>
            <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit1(item)} />
            <FontAwesomeIcon
              className="mx-3"
              icon={faTrash}
              onClick={() => handleBookOrderDelete(item._id)}
            />
          </div>
        ),
      }));

      setJsonData(data);
    } catch (error) {
      console.error(error.response || error.message);
      setIsError(error.response || error.message);
    } finally {
      // setIsLoadingData(false);
    }
  };

  const getMySubscriptionData = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Subscription/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      setMySubscriptionData(res.data);
    } catch (error) {
      setIsError(error.response);
    }
  };

  // const getMyprocessingData = async () => {
  //   const token = localStorage.getItem("token");
  //   setIsLoadingData(true);
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrder`,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     const data = res.data?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         status: item.status,
  //         Price: item.Price,

  //         actions: (
  //           <div>
  //             <FontAwesomeIcon
  //               icon={faEdit}
  //               onClick={() => handleEdit1(item)}
  //             />

  //             <FontAwesomeIcon
  //               className="mx-3"
  //               icon={faTrash}
  //               onClick={() => handleDelete(item._id)}
  //             />
  //           </div>
  //         ),
  //       };
  //     });
  //     setIsLoadingData(false);
  //     setJsonData(data);
  //     setIsLoadingData(false);
  //     // setDatatable((prevState) => ({
  //     //   ...prevState,
  //     //   rows: data,
  //     // }));
  //   } catch (error) {
  //     setIsError(error.response);
  //     setIsLoadingData(false);
  //   }
  // };

  //   const getMycompleteData = async () => {
  //     const token = localStorage.getItem("token");
  //     setIsLoadingData(true);
  //     try {
  //       const res = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrderByStatus?status=complete`,
  //         {
  //           headers: { Authorization: token },
  //         }
  //       );
  // console.log(res,"completedata&&&&&&&&&&&&&&&&&&");
  //       const data = res.data?.map((item, index) => {
  //         return {
  //           id: index + 1,
  //           status: item.status,
  //           Price: item.Price,

  //           actions: (
  //             <div>
  //               <FontAwesomeIcon
  //                 icon={faEdit}
  //                 onClick={() => handleEdit1(item)}
  //               />

  //               <FontAwesomeIcon
  //                 className="mx-3"
  //                 icon={faTrash}
  //                 onClick={() => handleDelete(item._id)}
  //               />
  //             </div>
  //           ),
  //         };
  //       });
  //       setIsLoadingData(false);
  //       setJsonData(data);
  //       setIsLoadingData(false);
  //       // setDatatable((prevState) => ({
  //       //   ...prevState,
  //       //   rows: data,
  //       // }));
  //     } catch (error) {
  //       setIsError(error.response);
  //       setIsLoadingData(false);
  //     }
  //   };

  const getMypaddingData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/getAllBookOrderByStatus?status=Pending`,
        {
          headers: { Authorization: token },
        }
      );
      console.log(res.data, "pendingdata^^^^^^^^^^^^^^");
      const data = res.data?.map((item, index) => {
        return {
          id: index + 1,
          status: item.status,
          price: item.price,
          phone: item.phone,
          name: item.name,
          email: item.email,
          buildingNumber: item.buildingNumber,
          City: item.City,
          LandMark: item.LandMark,
          Pincode: item.Pincode,
          State: item.State,
          order_id: item.order_id,

          actions: (
            <div>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => handleEdit1(item)}
              />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleBookOrderDelete(item._id)}
              />
            </div>
          ),
        };
      });
      setIsLoadingData(false);
      setJsonData(data);
      setIsLoadingData(false);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };
  const getMyBookData = async () => {
    const token = localStorage.getItem("token");
    setIsLoadingData(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/Book/getAll`,
        {
          headers: { Authorization: token },
        }
      );
      console.log("🚀 ~ getMyBookData ~ res:", res.data);

      const data = res.data?.map((item, index) => {
        // const base64Image = item.bookImg.replace(/^data:image\/\w+;base64,/, ''); // Remove the prefix
        return {
          id: index + 1,
          name: item.name,
          phone: item.phone,
          email: item.email,
          preparing: item.preparing,
          amount: item.amount,
          volume: item.volume,
          notesOverview: item.notesOverview,
          bookType: item.bookType,
          price: item.price,
          comboPrice: item.comboPrice,
          bookName: item.bookName,
          description: item.description,
          Neet_SS: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.Neet_SS}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          Neet_SS: item.Neet_SS ? "True" : "False",
          INISS_ET: (
            <input
              type="checkbox"
              style={{ margin: "10px -10px 0 0" }}
              checked={item.INISS_ET}
              onChange={(event) => handleToggleActive(event, item)}
            />
          ),
          INISS_ET: item.INISS_ET ? "True" : "False",
          // bookImg: base64Image,
          // bookImg: item.bookImg,
          _id: item._id,
          actions: (
            <div>
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEdit(item)} />

              <FontAwesomeIcon
                className="mx-3"
                icon={faTrash}
                onClick={() => handleDelete(item._id)}
              />
            </div>
          ),
        };
      });
      const handleToggleActive = (event, item, field) => {
        const updatedItem = { ...item, [field]: event.target.checked };
        const updatedItems = items.map((i) =>
          i.id === item.id ? updatedItem : i
        );
        setItems(updatedItems); // Update the state with the modified items array
      };
      setJsonData(data);
      setIsLoadingData(false);
      // setDatatable((prevState) => ({
      //   ...prevState,
      //   rows: data,
      // }));
    } catch (error) {
      setIsError(error.response);
      setIsLoadingData(false);
    }
  };

  const addBook = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/Book/create`,
        values,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMyData(res.data);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Book Added Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyBookData();
      onCloseModal();
      setMyData({
        bookName: "",
        description: "",
        bookType: "",
        // bookImg: [],
        volume: "",
        price: "",
        comboPrice: "",
        preparing_for: [],
        subscription_id: [],
        notesOverview: [],
      });
    } catch (error) {
      setIsError(error.response.data.err.message);
    }
  };
  // const addBook = async (values) => {
  //   const token = localStorage.getItem("token");
  //   try {
  //     const res = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL}/api/Book/create`,
  //       values,
  //       {
  //         headers: { Authorization: `${token}` },
  //       }
  //     );
  //     setMyData(res.data);
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Book Added Successfully ",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     getMyBookData();
  //     onCloseModal();
  //     setMyData({
  //       code: "",
  //       discountPrize: "",
  //     });
  //   } catch (error) {
  //     setIsError(error.response.data.err.message);
  //   }
  // };
  const updateBook = async (id, updatedData, setValues) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/updateBook/${id}`,
        updatedData,
        {
          headers: { Authorization: token },
        }
      );

      console.log(res.data, "update user");
      setValues(res.data.data); // Update the form values with the new data
      onEditCloseModal(); // Close the modal after successful update.
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Book Updated Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      getMyBookData();
    } catch (error) {
      console.error(error.response);
    }
  };

  // const updateOrderBook = async (
  //   id,
  //   updatedData,
  //   setSubmitting,
  //   onEditCloseOfferModal,
  //   getMyBookorderData
  // ) => {
  //   try {
  //     console.log(`Updating book order with ID: ${id}`); // Debugging log
  //     const token = localStorage.getItem("token");
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/updateBookOrder/${id}`,
  //       updatedData,
  //       {
  //         headers: { Authorization: token },
  //       }
  //     );

  //     console.log(res.data, "updatebookorde444444444roder****************");
  //     onEditCloseOfferModal(); // Close the modal after successful update.
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Order Updated Successfully",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //     getMyBookorderData(); // Refetch the book order data
  //   } catch (error) {
  //     console.error(error.response); // Handle error responses
  //     Swal.fire({
  //       position: "center",
  //       icon: "error",
  //       title: "Failed to Update Order",
  //       text: error.response?.data?.message || "An error occurred",
  //       showConfirmButton: true,
  //     });
  //   } finally {
  //     setSubmitting(false); // Reset form submitting state
  //   }
  // };
  const updateOrderBook = async (id, updatedData) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/api/updateBookOrder/${id}`,
      updatedData,
      {
        headers: { Authorization: token },
      }
    );
    
    console.log(id, "ididididididdi");
    console.log("🚀 ~ updateOrderBook ~ res:", res);
    console.log(res.data); // Log the response or handle it as required.

    onEditCloseOfferModal(); // Close the modal after successful update.
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Book order updated successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    getMyBookorderData();
  } catch (error) {
    console.error(error.response); // Handle error responses
  }
};

  
  const handleBookOrderDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("token");
          if (!token) throw new Error("No token found");

          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteBookOrder/${id}`,
            { headers: { Authorization: token } }
          );

          console.log(res.data, "delete&&&&&&&&&&&&&&&&&&&&&&&&&&&"); // Log the response or handle it as required.
          Swal.fire("Deleted!", "Your book order has been deleted.", "success");

          // Refresh the book order data after successful deletion
          getMyBookorderData();
        } catch (error) {
          console.error(error.response || error.message); // Handle error responses
          Swal.fire("Error", "Failed to delete the book order.", "error");
        }
      }
    });
  };
  const handleDelete = (id) => {
    // Show the confirmation dialog using SweetAlert
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        try {
          const token = localStorage.getItem("token");
          const res = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/api/deleteBook/${id}`,
            {
              headers: { Authorization: token },
            }
          );
          console.log(res.data, "delete"); // Log the response or handle it as required.
          // Optionally, you can refresh the subcategory data after successful deletion.
          // getMySubCategoryData();
          // Show a success message using SweetAlert
          Swal.fire("Deleted!", "Your book  has been deleted.", "success");
          getMyBookorderData();
        } catch (error) {
          console.error(error.response); // Handle error responses
          // Show an error message using SweetAlert
          Swal.fire("Error", "Failed to delete the Book.", "error");
        }
      }
    });
  };
  useEffect(() => {
    if (value === "1") {
      getMyBookData();
    } else if (value === "2") {
      getMyBookorderData();
    }
  }, [value]);

  const getPreparingFor = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/preparing/getpreparing`,
        {
          headers: { Authorization: token },
        }
      );
      setPreparingFor(
        res.data.map((i) => ({
          value: i.preparing_for,
          label: i.preparing_for,
        }))
      );
    } catch (error) {
      setIsError(error.response);
    }
  };

  const initialValues = {
    select_preparing: [], // Ensure this matches the name used in the form
  };

  const validationSchema = Yup.object().shape({
    select_preparing: Yup.array()
      .min(1, "At least one option must be selected")
      .required("This field is required"),
  });

  const handleChange = (event, newValue) => {
    console.log("🚀 ~ handleChange ~ newValue:", newValue);
    if (newValue === "1") {
      getMyBookData();
    } else {
      getMyBookorderData();
    }
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    console.log("🚀 ~ handleChange ~ newValue:", newValue);
    if (newValue === "5") {
      getMypaddingData();
    } else if (newValue === "6") {
      getMycompleteData();
    } else {
      // getMyBookData();
      getMyBookorderData();
    }
    setValue(newValue);
  };

  const handleEdit = (data) => {
    setEditData(data); // Set the data of the selected row
    setEditOpen(true); // Open the modal
  };
  const handleEdit1 = (data) => {
    setEdiBookOderData(data); // Set the data of the selected row
    setEditOfferOpen(true); // Open the modal
  };
  // const handleEditOffer = (data) => {
  //   setOpenOffer(data); // Set the data of the selected row
  //   setEditOfferOpen(true); // Open the modal
  // };

  // useEffect(() => {
  //   if (value === "2") {
  //     if (selectedMetalType === "Pending") {
  //       getMypaddingData();
  //     } else {
  //       getMycompleteData();
  //     }
  //   } else if (value === "1") {
  //     // getOrderProduct();
  //   }
  // }, [value, selectedMetalType]);
  useEffect(() => {
    if (value === "2") {
      // getMyprocessingData(); // Call getMyprocessingData for value "2"
      if (selectedMetalType === "Pending") {
        getMypaddingData();
      } else if(selectedMetalType === "Complete")  {
        getMycompleteData();
      }else{
        getMyprocessingData();
      }
    } else if (value === "1") {
      getMyBookData();
    }
  }, [value, selectedMetalType]);

  // useEffect(() => {
  //   if (selectedMetalType === "Pending") {
  //     getMypaddingData();
  //   } else {
  //     getMycompleteData();
  //   }
  // }, [selectedMetalType]);
  useEffect(() => {
    if (selectedMetalType === "Pending") {
      getMypaddingData();
    } else if (selectedMetalType === "Processing") {
      getMyprocessingData();
    } else if (selectedMetalType === "complete") {
      getMycompleteData();
    }
  }, [selectedMetalType]);

  const tabListStyles = {
    display: "flex",
    justifyContent: "center",
    background: "#f5f5f5",
    padding: "10px",
    borderRadius: "5px",
  };

  const tabStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "5px",
    cursor: "pointer",
    color: "#333",
    background: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out",
  };

  const activeTabStyles = {
    background: "#007bff", // Change this to the desired background color for the active tab
    color: "#fff", // Change this to the desired text color for the active tab
  };
  const handleMetalTypeChange = (type) => {
    setSelectedMetalType(type);
  };

  const SigninSchema = Yup.object().shape({
    bookName: Yup.string().required("Book Name is Required"),
    description: Yup.string().required("Description is Required"),
    bookType: Yup.string().required("Book Type is Required"),
    volume: Yup.string().required("Volume is Required"),
    price: Yup.string().required("Price is Required"),
    comboPrice: Yup.string().required("Combo Price is Required"),
  });

  const EditSchema = Yup.object().shape({
    bookName: Yup.string().required("Book name is required"),
    description: Yup.string().required("Description is required"),
    bookType: Yup.string().required("Book type is required"),
    volume: Yup.number().required("Volume is required").positive(),
    price: Yup.number().required("Price is required").positive(),
    comboPrice: Yup.number().required("Combo price is required").positive(),
  });
  const EditSchema1 = Yup.object().shape({
    status: Yup.string().required('Status is required'),

  });
  const EditSchemaorder = Yup.object().shape({
    status: Yup.string().required("Required"),
  });
  const [updatedBookData, setUpdatedBookData] = useState(editData);
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Book</h5>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="">
            <button className="btn btn-primary " onClick={onOpenModal}>
              Add Book
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button>

            {/* <button className="btn btn-primary" style={{ marginLeft: 5 }} onClick={onOpenOfferModal}>
              Add Book
              <FontAwesomeIcon icon={faPlus} className="mx-2" />
            </button> */}
          </div>
          {/* <DownloadExcelButton jsonData={jsonData}  fileName="book"/> */}
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={downloadCSV}
              variant="primary"
              style={{ backgroundColor: "#2042d0" }}
            >
              Download CSV <FontAwesomeIcon className="mx-2" />
            </Button>
          </div>
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2 className="md-12">Add Book</h2>
          <Formik
            initialValues={myData}
            validationSchema={SigninSchema}
            onSubmit={(values) => {
              addBook(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="bookName">
                      <BootstrapForm.Label>Book Name</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Book Name"
                        name="bookName"
                        value={values.bookName}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.bookName && touched.bookName && errors.bookName}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="bookType">
                      <BootstrapForm.Label>Book Type</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Book Type"
                        name="bookType"
                        value={values.bookType}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.bookType && touched.bookType && errors.bookType}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="volume">
                      <BootstrapForm.Label>Volume</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Volume"
                        name="volume"
                        value={values.volume}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.volume && touched.volume && errors.volume}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="comboPrice">
                      <BootstrapForm.Label>Combo Price</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="number"
                        placeholder="Enter Combo Price"
                        name="comboPrice"
                        value={values.comboPrice}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.comboPrice &&
                        touched.comboPrice &&
                        errors.comboPrice}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="price">
                      <BootstrapForm.Label>Price</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter Price"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.price && touched.price && errors.price}
                    </div>
                  </Col>

                  {!imgReload &&
                    Array.isArray(values.bookImg) &&
                    values.bookImg.map((img, imgIndex) => (
                      <div
                        className="image-area"
                        style={{ width: "90%" }}
                        key={imgIndex}
                      >
                        <img
                          src={`data:image/png;base64,${img}`}
                          alt={`Explanation ${imgIndex + 1}`}
                        />
                        <a
                          className="remove-image"
                          onClick={() => {
                            let after_delet = values.bookImg.splice(
                              imgIndex,
                              1
                            );
                            values.bookImg = values.bookImg.concat(after_delet);
                            setImgReload(true);
                            setTimeout(() => setImgReload(false), 1000);
                          }}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </a>
                      </div>
                    ))}

                  {/* <Col md={12} className="mb-3">
                <BootstrapForm.Group id="price">
                  <BootstrapForm.Label>bookImg</BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="file"
                    placeholder=" bookImg"
                    name="bookImg"
                    value={values.bookImg}
                    onChange={handleChange}
                  />
                </BootstrapForm.Group>
                <div className="mb-3 text-danger">
                  {errors.bookImg && touched.bookImg && errors.bookImg}
                </div>
              </Col> */}

                  {/* <Col md={12} className="mb-3">
  <div className="form-group">
    <label htmlFor="Image">bookImg</label>
    <input
      type="file"
      name="bookImg"
      onChange={(event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64String = e.target.result.replace(/^data:image\/\w+;base64,/, ''); // Remove the prefix
            setFieldValue("bookImg", base64String);
          };
          reader.readAsDataURL(file);
        }
      }}
      className="form-control"
    />
    <ErrorMessage
      name="Image"
      component="div"
      className="text-danger"
    />
  </div>
</Col> */}

                  {/* <Col md={12} className="mb-3">
                <BootstrapForm.Group id="explanation_image">
                  <BootstrapForm.Label>
                    Add {values.bookImg && values.bookImg[0] ? "More" : null} Book Image
                  </BootstrapForm.Label>
                  <BootstrapForm.Control
                    type="file"
                    name="[bookImg]"
                    multiple={true}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          const base64Data = e.target.result.split(",")[1];
                          setFieldValue(
                            "bookImg",
                            values.bookImg ? values.bookImg.concat(base64Data) : [base64Data]
                          );
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </BootstrapForm.Group>
              </Col> */}
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Select SubScription Plan</Form.Label>
                        <Select
                          name="subscription_id"
                          options={SubscriptionData.map((item) => ({
                            value: item.sid,
                            label: item.plan_name,
                          }))}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "subscription_id",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="description">
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <textarea
                        className="form-control"
                        id="description"
                        rows="6"
                        placeholder="Enter Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      ></textarea>
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>

                  <Col md={12} className="mb-3" style={{ zIndex: 100 }}>
                    <BootstrapForm.Group id="select_preparing">
                      <BootstrapForm.Label>
                        Select Preparing
                      </BootstrapForm.Label>
                      <Select
                        name="select_preparing"
                        value={values.preparing_for.map((id) => ({
                          value: id,
                          label: id,
                        }))}
                        options={[
                          { value: "Surgical", label: "Surgical" },
                          { value: "Non-Surgical", label: "Non-Surgical" },
                        ]}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "preparing_for",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                      {errors.select_preparing && (
                        <div className="text-danger">
                          {errors.select_preparing}
                        </div>
                      )}
                    </BootstrapForm.Group>
                  </Col>

                  <Form.Check
                    type="checkbox"
                    id="Neet_SS"
                    label="Neet_SS"
                    checked={values.Neet_SS}
                    onChange={(e) => {
                      setFieldValue("Neet_SS", e.target.checked);
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="INISS_ET"
                    label=" INISS_ET"
                    checked={values.INISS_ET}
                    onChange={(e) => {
                      setFieldValue("INISS_ET", e.target.checked);
                    }}
                  />

                  {/* <Col>
                    <Form.Check
                      type="checkbox"
                      id="INISS_ET"
                      label=" INISS_ET"
                      checked={values.INISS_ET}
                      onChange={(e) => {
                        setFieldValue("INISS_ET", e.target.checked);
                      }}
                    />
                  </Col> */}
                  <FieldArray name="notesOverview">
                    {({ push, remove }) => (
                      <>
                        {values?.notesOverview?.map((item, index) => (
                          <div key={index}>
                            <Row>
                              <Col md={4} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Chapter Name {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Chapter Name"
                                    name={`notesOverview[${index}].chapterName`}
                                    value={item.chapterName}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={4} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Chapter {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="number"
                                    placeholder="Enter Chapter Number"
                                    name={`notesOverview[${index}].chapter`}
                                    value={item.chapter}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={3} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Page Number {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Page Number"
                                    name={`notesOverview[${index}].pageNumber`}
                                    value={item.pageNumber}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={1} className="mb-3 align-self-end">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}

                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                push({
                                  chapterName: "",
                                  chapter: "",
                                  pageNumber: "",
                                })
                              }
                            >
                              Add Chapter
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>

                  <div className="mt-3">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          {isError && <div className="text-danger">{isError}</div>}
        </Modal>
        <Modal open={Editopen} onClose={onEditCloseModal} center>
          <h2>Edit Book</h2>
          <Formik
            initialValues={editData}
            validationSchema={EditSchema}
            onSubmit={(values, { setValues }) => {
              updateBook(editData._id, values, setValues); // Pass the ID, updated data, and setValues to updateUser
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="bookName">
                      <BootstrapForm.Label>Book Name</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter book name"
                        name="bookName"
                        value={values.bookName}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.bookName && touched.bookName && errors.bookName}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="description">
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.description &&
                        touched.description &&
                        errors.description}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Select SubScription Plan</Form.Label>
                        <Select
                          name="subscription_id"
                          options={SubscriptionData.map((item) => ({
                            value: item.sid,
                            label: item.plan_name,
                          }))}
                          isMulti
                          onChange={(selectedOptions) => {
                            setFieldValue(
                              "subscription_id",
                              selectedOptions.map((option) => option.value)
                            );
                          }}
                        />
                      </Form.Group>
                    </Col>
                </Row>
                <Col md={12} className="mb-3" style={{ zIndex: 100 }}>
                    <BootstrapForm.Group id="select_preparing">
                      <BootstrapForm.Label>
                        Select Preparing
                      </BootstrapForm.Label>
                      <Select
                        name="select_preparing"
                        value={values.preparing_for.map((id) => ({
                          value: id,
                          label: id,
                        }))}
                        options={[
                          { value: "Surgical", label: "Surgical" },
                          { value: "Non-Surgical", label: "Non-Surgical" },
                        ]}
                        isMulti
                        onChange={(selectedOptions) => {
                          setFieldValue(
                            "preparing_for",
                            selectedOptions.map((option) => option.value)
                          );
                        }}
                      />
                      {errors.select_preparing && (
                        <div className="text-danger">
                          {errors.select_preparing}
                        </div>
                      )}
                    </BootstrapForm.Group>
                  </Col>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="bookType">
                      <BootstrapForm.Label>Book Type</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter book type"
                        name="bookType"
                        value={values.bookType}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.bookType && touched.bookType && errors.bookType}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="volume">
                      <BootstrapForm.Label>Volume</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="number"
                        placeholder="Enter volume"
                        name="volume"
                        value={values.volume}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.volume && touched.volume && errors.volume}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="price">
                      <BootstrapForm.Label>Price</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="number"
                        placeholder="Enter price"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.price && touched.price && errors.price}
                    </div>
                  </Col>
                  <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="comboPrice">
                      <BootstrapForm.Label>Combo Price</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="number"
                        placeholder="Enter combo price"
                        name="comboPrice"
                        value={values.comboPrice}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.comboPrice &&
                        touched.comboPrice &&
                        errors.comboPrice}
                    </div>
                  </Col>
                  <Row>
                    <Form.Check
                      type="checkbox"
                      id="Neet_SS"
                      label="Neet_SS"
                      checked={values.Neet_SS}
                      onChange={(e) => {
                        setFieldValue("Neet_SS", e.target.checked);
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="INISS_ET"
                      label=" INISS_ET"
                      checked={values.INISS_ET}
                      onChange={(e) => {
                        setFieldValue("INISS_ET", e.target.checked);
                      }}
                    />
                  </Row>
                  {/* <Col md={12} className="mb-3">
                    <BootstrapForm.Group id="notesOverview">
                      <BootstrapForm.Label>NotesOverview</BootstrapForm.Label>
                      <BootstrapForm.Control
                        type="text"
                        placeholder="Enter notesOverview"
                        name="notesOverview"
                        value={values.notesOverview}
                        onChange={handleChange}
                      />
                    </BootstrapForm.Group>
                    <div className="mb-3 text-danger">
                      {errors.notesOverview &&
                        touched.notesOverview &&
                        errors.notesOverview}
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <FieldArray name="notesOverview">
                    {({ push, remove }) => (
                      <>
                        {values?.notesOverview?.map((item, index) => (
                          <div key={index}>
                            <Row>
                              <Col md={4} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Chapter Name {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Chapter Name"
                                    name={`notesOverview[${index}].chapterName`}
                                    value={item.chapterName}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={4} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Chapter {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="number"
                                    placeholder="Enter Chapter Number"
                                    name={`notesOverview[${index}].chapter`}
                                    value={item.chapter}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>
                              <Col md={3} className="mb-3">
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>
                                    Page Number {index + 1}
                                  </BootstrapForm.Label>
                                  <BootstrapForm.Control
                                    type="text"
                                    placeholder="Enter Page Number"
                                    name={`notesOverview[${index}].pageNumber`}
                                    value={item.pageNumber}
                                    onChange={handleChange}
                                  />
                                </BootstrapForm.Group>
                              </Col>

                              <Col md={1} className="mb-3 align-self-end">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => remove(index)}
                                >
                                  Remove
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        ))}

                        <Row>
                          <Col md={12} className="mb-3">
                            <Button
                              variant="primary"
                              onClick={() =>
                                push({
                                  chapterName: "",
                                  chapter: "",
                                  pageNumber: "",
                                })
                              }
                            >
                              Add Chapter
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </FieldArray>
                  {/* <Col md={12} className="mb-3">
                <BootstrapForm.Label>Subscription</BootstrapForm.Label>
                <Select
                  name="subscription_id"
                  value={values.subscription_id.map((id) => ({
                    value: id,
                    label: options.find(option => option.value === id)?.label || id,
                  }))}
                  options={options}
                  isMulti
                  onChange={(selectedOptions) => {
                    setFieldValue(
                      "subscription_id",
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                />
                <div className="mb-3 text-danger">
                  {errors.subscription_id && touched.subscription_id && errors.subscription_id}
                </div>
              </Col> */}
                </Row>
                <div className="mt-3">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
        <Modal open={EditOfferopen} onClose={onEditCloseOfferModal} center>
      <h2>Edit Book Order</h2>
      <Formik
        initialValues={editBookOderData}
        validationSchema={EditSchema1}
        onSubmit={(values, { setValues }) => {
          updateOrderBook(editBookOderData._id, values, setValues); // Pass the ID, updated data, and setValues to updateUser
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form>
            <Row>
             
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <BootstrapForm.Group id="status">
                  <BootstrapForm.Label>Status</BootstrapForm.Label>
                  <BootstrapForm.Control
                    as="select"
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                  >
                    <option value="">Select status</option>
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="Completed">Completed</option>
                  </BootstrapForm.Control>
                </BootstrapForm.Group>
                <div className="mb-3 text-danger">
                  {errors.status && touched.status && errors.status}
                </div>
              </Col>
            </Row>
            <div className="mt-3">
              <Button
                variant="primary"
                type="submit"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>

        {/* <Modal open={EditOfferopen} onClose={onEditCloseOfferModal} center>
      <h2>Edit Book Order</h2>
      <Formik
        initialValues={editBookOderData}
        validationSchema={EditSchemaorder}
        onSubmit={(values, { setSubmitting }) => {
          if (!editData || !editData._id) {
            console.error("editData or editData._id is missing"); // Debugging log
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Missing Order ID",
              text: "Unable to update order. Order ID is missing.",
              showConfirmButton: true,
            });
            setSubmitting(false);
            return;
          }
          updateOrderBook(editData._id, values, setSubmitting, onEditCloseOfferModal, getMyBookorderData);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <BootstrapForm.Group controlId="status">
                  <BootstrapForm.Label>Status</BootstrapForm.Label>
                  <Field as="select" name="status" className="form-control">
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                    <option value="Processing">Processing</option>
                  </Field>
                  <ErrorMessage name="status" component="div" className="mb-3 text-danger" />
                </BootstrapForm.Group>
              </Col>
              <Col md={12} className="mt-3">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal> */}

        <TabContext value={value}>
          <TabList style={tabListStyles} onChange={handleChange}>
            <Tab
              style={
                value === "1" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="Book"
              value="1"
            />
            <Tab
              style={
                value === "2" ? { ...tabStyles, ...activeTabStyles } : tabStyles
              }
              label="Book order"
              value="2"
            />
          </TabList>
          {/* <div>
            {isLoadingData ? (
              <div className="loader-container">
                <Image
                  className="loader-element animate__animated animate__jackInTheBox"
                  src={ReactLogo}
                  height={40}
                />
              </div>
            ) : (
              <></>
            )}
          </div> */}
          <TabPanel value="2" onChange={handleChange1}>
      <>
        <div>
          <Button
            variant="primary"
            onClick={() => handleMetalTypeChange('Pending')}
            style={buttonStyles('Pending')}
            className="mr-2"
          >
            Pending
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleMetalTypeChange('Complete')}
            style={buttonStyles('Complete')}
          >
            Complete
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleMetalTypeChange('Processing')}
            style={buttonStyles('Processing')}
          >
            Processing
          </Button>
        </div>
        <MaterialReactTable columns={columns5} data={jsonData} />

        {/* <Button href="padding#/padding/" style={{ margin: "5px" }}>
          Pending order
        </Button>
        <Button href="padding#/Complete" style={{ margin: "5px" }}>
          Complete order
        </Button>
        <Button href="padding#/Processing">Processing order</Button> */}
      </>
      {/* 
      <MaterialReactTable
        autoResetPageIndex={false}
        columns={columns5}
        data={jsonData}
        enableRowOrdering
        enableSorting={false}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();
            if (hoveredRow && draggingRow) {
              jsonData.splice(
                hoveredRow.index,
                0,
                jsonData.splice(draggingRow.index, 1)[0]
              );
              setJsonData([...jsonData]);
              console.log("new jsonData", jsonData);
              let position_data = {
                Offer_id: [],
                position: [],
              };
              jsonData.forEach((element, index) => {
                position_data.Offer_id.push(element._id);
                position_data.position.push(index + 1);
              });
            }
          },
        })}
      /> */}
    </TabPanel>
          <TabPanel value="1">
            {/* <div>
              {isLoadingData ? (
                <div className="loader-container">
                  <Image
                    className="loader-element animate__animated animate__jackInTheBox"
                    src={ReactLogo}
                    height={40}
                  />
                </div>
              ) : (
                <></>
              )}
            </div> */}
            <MaterialReactTable
              autoResetPageIndex={false}
              columns={columns1}
              data={jsonData}
              enableRowOrdering
              enableSorting={false}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    jsonData.splice(
                      hoveredRow.index,
                      0,
                      jsonData.splice(draggingRow.index, 1)[0]
                    );
                    setJsonData([...jsonData]);
                    console.log("new jsonData", jsonData);
                    let position_data = {
                      Offer_id: [],
                      position: [],
                    };
                    jsonData.forEach((element, index) => {
                      position_data.Offer_id.push(element._id);
                      position_data.position.push(index + 1);
                    });
                  }
                },
              })}
            />
          </TabPanel>
        </TabContext>
      </Card.Body>
    </Card>
  );
};
