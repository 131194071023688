export const Routes = {
  // pages
  // dynamicpopup: { path: "/dynamicpopup" },
  Presentation: { path: "/Presentation" },
  DashboardOverview: { path: "/dashboard/overview" },
  Transactions: { path: "/transactions" },
  Category: { path: "/category" },
  Subcategory: { path: "/subcategory" },
  CouponCode: { path: "/couponCode" },
  Topics: { path: "/topics" },
  Subscription: { path: "/subscription" },
  SubscriptionData: { path: "/subscriptiondata" },
  Content: { path: "/content" },
  Query: { path: "/queries" },
  Quiz: { path: "/Quiz" },
  Exam: { path: "/exam" },
  Padding: { path: "/padding" },
  Complete: { path: "/Complete" },
  Processing: { path: "/Processing" },
  Testimonials: { path: "/testimonials" },
  TeamMembers: { path: "/teamMembers" },
  Count: { path: "/count" },
  Blog: { path: "/blog" },
  BlogCategory: { path: "/blogCategory" },
  Notification: { path: "/notification" },
  Banner: { path: "/banner" },
  Quations: { path: "/questions" },
  CommanForm: { path: "/bulkUpload" },
  Users: { path: "/users" },
  Book: { path: "/Book" },
  PopupNotification: { path: "/PopupNotification" },
  QuizDetails: { path: "/QuizDetails" },
  UserDetails: { path: "/userDetails" },
  QuestionDetails: { path: "/questionDetails" },
  MockQuestionDetails: { path: "/mockQuestionDetails" },
  Upgrade: { path: "/upgrade" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/examples/sign-in" },
  Signin: { path: "/" },

  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
  Positions: { path: "/positions" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
  PreparingFor: { path: "/preparingFor" },
  Setting: { path: "/setting" },
  MockExamCategory: { path: "/mockExamCategory" },
  MockExam: { path: "/mockExam" },
  MockQuestion: { path: "/mockQuestion" },
  ZoomMeeting: { path: "/zoomMeeting" },
  // dynamicpopup: { path: "/dynamicpopup" },
};
